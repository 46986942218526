<template>
  <van-sticky>
    <nav class="van-nav-bar">
      <router-link to="/"> 图灵编程 </router-link>
      |
      <router-link to="/about">图灵托管</router-link>
    </nav>
  </van-sticky>
  <router-view />
</template>

<script lang="js">
import { ref } from "vue";

export default {
  setup() {
    const active = ref(0);
    return { active };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 修改 vant-nav-bar 的背景色为透明 */
.van-nav-bar {
  /* background-color: rgba(255, 255, 255, 0) !important; */
}

nav {
  padding: 10px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
