<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <img class="" alt="logo" src="../assets/title.png" /> -->
    <div class="content">
      <!-- <van-watermark content="Vant" /> -->
      <br />
      <h3 style="color: #fef00b; text-align: left">开班课程及课时安排表</h3>
      <template v-for="langItem in lang" :key="langItem">
        <van-divider
          :style="{
            color: '#fff',
            borderColor: '#febb13',
            padding: '0 16px',
            fontSize: '16px',
          }"
        >
          {{ langItem[0] }}
        </van-divider>
        <!-- <van-text-ellipsis
          class="langtext"
          rows="100"
          :content="langItem[1]"
          expand-text="展开"
          collapse-text="收起"
        /> -->
        <p class="with-border">{{ langItem[1] }}</p>
      </template>
      <!-- 学期课程 -->
      <table class="custom-table">
        <thead>
          <tr>
            <th v-for="termSetItem in termSet" :key="termSetItem">
              {{ termSetItem }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in termClasses" :key="index">
            <td v-for="ite in item" :key="ite">{{ ite }}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <!-- 假期课程 -->
      <table class="custom-table">
        <thead>
          <tr>
            <th v-for="termSetItem in holidayTermSet" :key="termSetItem">
              {{ termSetItem }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in holidayClasses" :key="index">
            <td v-for="ite in item" :key="ite">{{ ite }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 价格 -->
      <h3 style="color: #fef00b; text-align: left">价格设置</h3>
      <!-- <van-divider
        :style="{
          color: '#fff',
          borderColor: '#1989fa',
          padding: '0 16px',
          fontSize: '15px',
        }"
      >
        价格设置
      </van-divider> -->
      <table class="custom-table">
        <thead>
          <tr>
            <th v-for="priceColumeItem in priceColume" :key="priceColumeItem">
              {{ priceColumeItem }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in priceInfo" :key="index">
            <td v-for="ite in item" :key="ite">{{ ite }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 师资队伍 -->
      <h3 style="color: #fef00b; text-align: left">师资队伍</h3>
      <!-- <van-divider
        :style="{
          color: '#fff',
          borderColor: '#1989fa',
          padding: '0 16px',
          fontSize: '15px',
        }"
      >
        师资队伍
      </van-divider> -->
      <div class="qa">
        {{ teachersInfo }}
      </div>
      <!-- 咨询方式 -->
      <h3 style="color: #fef00b; text-align: left">咨询方式</h3>

      <van-row>
        <template v-for="teacherTel in telephone" :key="teacherTel">
          <van-col span="5">
            <van-image fit="cover" :src="teacherTel[0]" />
          </van-col>
          <van-col span="19">
            <p>
              <van-icon name="phone" />{{ teacherTel[1] }}：{{
                teacherTel[2]
              }}({{ teacherTel[3] }})
            </p>
          </van-col>
        </template>
      </van-row>
      <!-- 备注 -->
      <van-divider
        :style="{
          color: '#fff',
          borderColor: '#1989fa',
          padding: '0 16px',
          fontSize: '15px',
        }"
      >
        备注
      </van-divider>
      <div class="qa">
        <ul>
          <li v-for="item in extra" :key="item">· {{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import qrChen from "@/assets/qr-chen.png";
import qrSu from "@/assets/qr-su.png";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  setup() {
    const lang = {
      // objname: [
      //   班级名称，
      //   班级教授内容，
      //   编程语言
      // ],
      js: [
        "JavaScript语言班",
        "JavaScript是一种客户端脚本语言，具有门槛低、入门快等优点，培养思维及独立解决问题的能力，且广泛应用于网页网站、APP、小程序的设计中。",
        "JavaScript",
      ],
      c: [
        "C/C++算法班",
        "C/C++是高级编程语言的基石，具有广泛应用的特点，应用于工业自动化、新能源汽车自动驾驶、手机、电脑系统、4G、5G通讯等领域。",
        "C/C++",
      ],
      py: [
        "Python人工智能大数据班",
        "Python是一门多平台兼容的编程语言，在人工智能、大数据、人脸识别等高尖科技领域广泛应用。",
        "Python",
      ],
      scratch: [
        "Scratch编程兴趣班",
        "Scratch是美国麻省理工学院设计的面向儿童的编程工具，具有简洁易懂的优点，可以培养逻辑思维能力，对学科的学习亦有促进作用。",
        "Scratch",
      ],
      classes: [
        "班级开设",
        "每个培训日安排4课时。1课时=2小时，包含20分钟的休息答疑时间",
      ],
    };
    const lessonUnit = "课时";
    const lessonSetting = {
      am: "上午(2" + lessonUnit + ")",
      pm: "下午(2" + lessonUnit + ")",
    };
    // 创建一个代理对象，为每个属性添加get和set方法
    const langProxy = {};
    for (const key in lang) {
      if (Array.isArray(lang[key])) {
        langProxy[key] = new Proxy(lang[key], {
          get(target, prop) {
            if (prop === "name") {
              return target[2];
            }
            return target[prop];
          },
          set(target, prop, value) {
            if (prop === "name") {
              target[2] = value;
            } else {
              target[prop] = value;
            }
            return true;
          },
        });
      }
    }
    const repeatValue = (value, count) => {
      return Array.from({ length: count }, () => value);
    };
    const termSet = ["时间 / 学期", "秋季学期 - 32课时", "春季学期 - 32课时"];
    const termClasses = [
      ["周六" + lessonSetting.am, ...repeatValue(langProxy.js.name, 2)], // 上课时间，秋季开班，春季开班
      ["周六" + lessonSetting.pm, ...repeatValue(langProxy.c.name, 2)],
      ["周日" + lessonSetting.am, ...repeatValue(langProxy.py.name, 2)],
      ["周六" + lessonSetting.pm, ...repeatValue(langProxy.scratch.name, 2)],
    ];
    termClasses.forEach((item) => {
      item[1] = item[1] += "基础班/中级班";
      item[2] = item[2] += "进阶班/高级班";
    });
    // 暑假寒假班数据
    const holidayTermSet = ["时间 / 学期", "夏令营/冬令营(34课时)"];
    const holidayClasses = [
      ["每日" + lessonSetting.am, ...repeatValue(langProxy.js.name, 1)], // 上课时间，秋季开班，春季开班
      ["每日" + lessonSetting.pm, ...repeatValue(langProxy.c.name, 1)],
      ["每日" + lessonSetting.am, ...repeatValue(langProxy.py.name, 1)],
      ["每日" + lessonSetting.pm, ...repeatValue(langProxy.scratch.name, 1)],
    ];
    holidayClasses.forEach((item) => {
      item[1] = "基于" + item[1] + "的实战课程";
    });
    // 价格设置
    const priceColume = ["套餐编号", "套餐名称", "价 格"];
    const priceInfo = [
      ["套餐一", "短期课程(3" + lessonUnit + "起)", "120/" + lessonUnit],
      ["套餐二", "学期班(32" + lessonUnit + ")", "3399(88折)"],
      ["套餐三", "学期班+夏/冬令营(66" + lessonUnit + ")", "6899(88折)"],
      ["套餐四", "学年班(64" + lessonUnit + ")", "6499(85折)"],
      ["套餐五", "学年班+夏令营+冬令营(132" + lessonUnit + ")", "13399(85折)"],
    ];
    // 师资队伍
    const teachersInfo =
      "我们聘请的教师均来自安徽工业大学、马鞍山学院教师、研究生等、合肥H3C、银行研发工程师等知名大厂企业员工，马鞍山学院计算机杨老师、安徽工业大学计算机王老师具有多年高校教学经验，知识储备丰富，合肥H3C资深顾问徐工全程提供技术支持。";
    // 联系方式
    const telephone = [
      [qrChen, "陈老师", "18955524556", "微信同号"],
      [qrSu, "苏老师", "15010905245", "微信同号"],
    ];
    // 备注说明
    const extra = [
      "1课时=2小时（包括休息时间40分钟）",
      "采用小班课模式，5-8人/班，实际开班人数随实际调整",
      "目前新店开业，试运营期间价格优惠，预计半年后价格会有所调整",
      "最低满5人开班",
      "乐高编程班正在筹备中",
    ];
    return {
      lang, // 编程语言
      termSet, // 学期
      termClasses,
      holidayTermSet, // 假期
      holidayClasses,
      priceColume, // 价格
      priceInfo,
      teachersInfo, // 师资队伍
      extra, //备注
      telephone, // 联系方式
    };
  },
};
</script>

<style>
.home {
  background-image: url(../assets/background.png);
  background-size: 100%; /* 确保背景图像覆盖整个元素 */
  background-repeat: no-repeat; /* 防止背景图像重复 */
  background-position: center 0px; /* 使背景图像居中*/
  background-color: #1c1874;
  /* background-attachment: fixed; */
  /* background-position-y: 30px; */
  /* height: 100%; */
}

.content {
  padding: 50px 10px;
  color: #fff;
}

.langtext {
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.qa {
  text-align: left;
}

.with-border {
  border-top: 2px solid #febb13;
  border-left: 2px solid #febb13;
  border-right: 2px solid #febb13;
  border-bottom: 2px solid #febb13;
  border-radius: 10px;
  padding: 10px;
}

/* .custom-table th {
  background-color: #f2f2f2;
  font-weight: bold;
} */

/* .custom-table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* .custom-table tr:hover {
  background-color: #e0e0e0;
} */
</style>
